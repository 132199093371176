import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AndroidService } from "../providers/android.service";
import { Router, RouterOutlet } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  mainRoutes = [
    'home',
    'trainings',
    'map',
    'agenda',
    'change-password',
    'rules',
    'privacy-policy',
    'score-history',
    'info',
    'code',
    'ranks',
    'profile',
    'user-score',
    'qa-terms'
  ];
  startPage = '/home';
  @ViewChild(RouterOutlet) outlet : RouterOutlet;
  outletSubscription: Subscription = null;

  constructor(
    private androidService: AndroidService,
    private router: Router,
    private location: Location,
  ) {
    this.location.subscribe((event) => {
      const isMainRoute = this.mainRoutes.some(r => ('/' + r) === event.url);
      if (isMainRoute && event.pop && event.url !== this.startPage) {
        setTimeout(() => {
          this.router.navigate([this.startPage]);
        }, 20)
      }
    })
  }

  ngAfterViewInit() {
    this.outletSubscription = this.outlet.activateEvents.subscribe(res => {
      setTimeout(() => {
        this.androidService.hideSplashScreen();
        this.outletSubscription.unsubscribe();
      }, 3000);
    })
  }
}
