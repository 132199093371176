<div class="main-body privacy-policy">
  <a [routerLink]="['/']" class="return-anchor top"><i class="fa-solid fa-arrow-turn-down-left"></i>Powrót do strony głównej</a>

  <div class="logo">
    <img [src]="'./assets/ui/logo.png'">
  </div>

  <div class="content">
    <h2>ZASADY PRZETWARZANIA DANYCH OSOBOWYCH
      W
      <a class="ignore-weight" href="WWW.TRAINAPP.PL">„TRAINAPP”</a></h2>

    <p class="non-padded">Poniżej przedstawiamy informacje o tym w jaki sposób oraz w jakich celach przetwarzamy Twoje
      dane osobowe w związku z korzystaniem z „TrainApp”, czyli usługi cyfrowej pozwalającej m.in. na
      rejestrację swojego udziału w targach produktowych.<br>
      TrainApp dostępna jest zarówno w formie aplikacji (dla urządzeń z systemem Android), jak i strony
      internetowej. </p>
    <p class="bold padded"><span class="bullet">1.</span>Dane </p>
    <p class="non-padded">Współadministratorami Twoich danych osobowych są spółki z Grupy Media Expert. Grupę Media
      Expert tworzą spółki odpowiedzialne za prowadzenie sprzedaży w sklepach Media Expert, w tym
      również na mediaexpert.pl oraz w aplikacji Media Expert, a także za dostawę towarów na
      terytorium Polski (dalej: Media Expert).<br><br>
      Jeżeli chciałbyś się z nami skontaktować, wystarczy, że napiszesz do nas mail na adres
      <a href="mailto:inspektor@me.pl">inspektor@me.pl</a> lub prześlesz list na adres: „Media Expert” TERG S.A., ul. Za Dworcem 1D, 77-
      400 Złotów z dopiskiem „Dane osobowe”. Kontakt z nami jest łatwy, ponieważ w Media Expert
      wyznaczyliśmy jeden punkt kontaktowy dla wszystkich spraw dotyczących danych osobowych.<br><br>
      Ochrona Twoich danych osobowych jest naszym priorytetem. Dlatego, aby ją dodatkowo
      wzmocnić, wyznaczyliśmy w Media Expert Inspektora Ochrony Danych (dalej: Inspektor). Naszym
      Inspektorem jest Marcin Sikora (kontakt: <a href="mailto:inspektor@me.pl">inspektor@me.pl</a>).</p>
    <p class="bold padded"><span class="bullet">2.</span>Zbieranie danych </p>
    <p class="non-padded">Sposób zbierania danych zależy od Twojego statusu.<br><br>
      Wyróżniamy:</p>
    <p><span class="bullet">a.</span>Uczestnika,</p>
    <p><span class="bullet">b.</span>Gościa ME,</p>
    <p><span class="bullet">c.</span>Gościa zewnętrznego,</p>
    <p><span class="bullet">d.</span>Trenera.</p>
    <p class="non-padded">Jeśli jesteś gościem ME, gościem zewnętrznym albo trenerem, to wszystkie dane otrzymujemy
      bezpośrednio od Ciebie. W trakcie rejestracji podajesz nam: </p>
    <p><span class="bullet">a.</span>HR ID, imię, nazwisko, dział, email – dot. gościa ME,</p>
    <p><span class="bullet">b.</span>imię, nazwisko, email oraz firmę – dot. gościa zewnętrznego i trenera.</p>
    <p class="non-padded">Dane do TrainApp mógł też wprowadzić Dział Szkoleń, który odpowiada za utworzenie kont
      pracowników ME obowiązanych do zrealizowania określonych aktywności podczas targów
      (uczestnik). </p>
    <p class="non-padded">Podanie danych osobowych jest konieczne, aby korzystać z TrainApp.</p>
    <p class="non-padded">W aplikacji oraz na stronie internetowej stosujemy wyłącznie pliki cookies niezbędne.</p>
    <p class="non-padded">Twoje dane nie będą podlegały profilowaniu ani zautomatyzowanemu podejmowaniu decyzji.</p>

    <p class="bold padded"><span class="bullet">3.</span>Cele przetwarzania i podstawa prawna </p>
    <p class="non-padded">Dane przetwarzamy w celu świadczenia usługi TrainApp, która jest usługą dedykowaną dla
      odwiedzających targi produktowe. Przetwarzamy dane, gdyż jest to niezbędne do wykonania
      umowy o prowadzenie konta w TrainApp.</p>
    <p class="non-padded">Rejestracja w TrainApp daje możliwość wejścia do przestrzeni targowej i zapoznania się z agendą
      targów. </p>
    <p class="non-padded">W przypadku uczestników TrainApp służy również do wyznaczania zadań do zrealizowania i
      weryfikacji wykonania tych zadań np. poprzez generowanie wyników osiągniętych w testach przez
      poszczególnych uczestników.</p>

    <p class="bold padded"><span class="bullet">4.</span>Okres przetwarzania danych </p>
    <p class="non-padded">Przetwarzamy dane osobowe do czasu korzystania przez Ciebie z TrainApp.</p>
    <p class="non-padded">Po tym okresie Twoje dane możemy przechowywać dlatego, że:</p>
    <p><span class="bullet dot">•</span>dane mogą być dla nas niezbędne do wykazania, że nasze działanie było zgodne z prawem
      (np. dane o wyrażonej przez Ciebie zgodzie na kontakt marketingowy);</p>
    <p><span class="bullet dot">•</span>nie minął jeszcze termin przedawnienia Twoich roszczeń.</p>
    <p class="bold padded"><span class="bullet">5.</span>Odbiorcy danych</p>
    <p class="non-padded">Twoje dane mogą być ujawniane innym podmiotom tylko wówczas, gdy jest to niezbędne do
      zrealizowania działań związanych z funkcjonowaniem TrainApp. </p>
    <p class="non-padded">Twoje dane możemy ujawniać firmą świadczącym usługi IT, dzięki czemu możemy trzymywać
      oprogramowanie. </p>
    <p class="non-padded">W każdym przypadku, gdy Twoje dane są ujawniane innym podmiotom, dzieję się to w ramach
      obowiązujących przepisów. Jeśli partnerzy lub usługodawcy przetwarzają Twoje dane w imieniu
      Media Expert, muszą zapewnić gwarancje wdrożenia rozwiązań technicznych i organizacyjnych,
      które będą chroniły Twoje dane osobowe i spełniały wymogi ogólnego Rozporządzenia o Ochronie
      Danych Osobowych (RODO).</p>
    <p class="bold padded"><span class="bullet">6.</span>Prawa podmiotów danych</p>
    <p class="non-padded">Prawo ochrony danych osobowych daje Ci szereg praw, z których możesz skorzystać w dowolnej
      chwili. Twoje prawa obejmują:</p>
    <p><span class="bullet">a.</span>prawo dostępu do treści swoich danych osobowych; </p>
    <p><span class="bullet">b.</span>prawo do poprawiania danych;
    </p>
    <p><span class="bullet">c.</span>prawo do ograniczenia przetwarzania danych;</p>
    <p><span class="bullet">d.</span>prawo do żądania usunięcia danych;</p>
    <p><span class="bullet">e.</span>prawo do przenoszenia danych do innego administratora danych.</p>
    <p class="non-padded">Uprawnienia, o których mowa powyżej, możesz wykonywać poprzez kontakt pod adresem e-mail
      <a href="mailto:inspektor@me.pl">inspektor@me.pl</a> lub pisząc na adres „Media Expert” TERG S.A., ul. Za Dworcem 1D, 77-400
      Złotów, z dopiskiem „Dane osobowe”. </p>
    <p class="non-padded">Jeżeli uznasz, że w jakikolwiek sposób naruszone zostały reguły przetwarzania Twoich danych
      osobowych, masz prawo do złożenia skargi bezpośrednio do organu nadzoru (Prezes Urzędu
      Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa). W ramach wykonania tego
      uprawnienia powinieneś podać pełny opis zaistniałej sytuacji oraz wskazać jakie działanie
      uznajesz za naruszające Twoje prawa lub wolności. Skargę należy złożyć bezpośrednio do organu
      nadzoru.
    </p>
    <p class="bold padded"><span class="bullet">7.</span>Transfer danych poza EOG</p>
    <p class="non-padded">Twoje dane nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej.
    </p>
    <p class="bold padded"><span class="bullet">8.</span>Lista spółek z Grupy Media Expert</p>
    <p class="non-padded">TERG S.A., ul. Za Dworcem 1D, 77-400 Złotów, REGON 570217011, NIP: 7671004218, KRS
      0000427063 (usługodawca);
    </p>
    <p class="non-padded">ME M01 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384110047, NIP: 7671714109,
      KRS 0000798896;
    </p>
    <p class="non-padded">ME M02 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099558, NIP: 7671714049,
      KRS 0000798323;
    </p>
    <p class="non-padded">ME M03 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099564, NIP: 7671714055,
      KRS 0000798325;
    </p>
    <p class="non-padded">ME M04 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099601, NIP: 7671714061,
      KRS 0000798326;
    </p>
    <p class="non-padded">ME M05 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384110099, NIP: 7671714115,
      KRS 0000798899;
    </p>
    <p class="non-padded">ME M06 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099535, NIP: 7671714026,
      KRS 0000798327;
    </p>
    <p class="non-padded">ME M07 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384057459, NIP: 7671713943,
      KRS 0000797868;
    </p>
    <p class="non-padded">ME M08 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 690457914, NIP: 8132199474,
      KRS 0000108761;
    </p>
    <p class="non-padded">ME M09 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099417, NIP: 7671714032,
      KRS 0000798328;
    </p>
    <p class="non-padded">ME M10 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099268, NIP: 7671713995,
      KRS 0000798330;
    </p>
    <p class="non-padded">ME M11 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384091083, NIP: 7671713972,
      KRS 0000797960;
    </p>
    <p class="non-padded">ME M12 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099386, NIP: 7671714003,
      KRS 0000798331.
    </p>
    <p class="bold padded"><span class="bullet">9.</span>Pobieranie aplikacji w sklepie z aplikacjami </p>
    <p class="non-padded">Podczas pobierania aplikacji operatorzy sklepu z aplikacjami (Google Play) automatycznie
      przetwarza dane w szczególności takie jak:
    </p>
    <p><span class="bullet dot">•</span>nazwa użytkownika w sklepie z aplikacjami,</p>
    <p><span class="bullet dot">•</span>adres e-mail zapisany w sklepie z aplikacjami,</p>
    <p><span class="bullet dot">•</span>numer klienta z Twojego konta w sklepie z aplikacjami,
    </p>
    <p><span class="bullet dot">•</span>data i czas pobrania,</p>
    <p><span class="bullet dot">•</span>indywidualny numer identyfikacyjny urządzenia.</p>
    <p class="non-padded">Nie mamy wpływu na to przetwarzanie danych i nie ponosimy za nie odpowiedzialności. Więcej
      informacji w tym zakresie możesz znaleźć w Polityce prywatności danego sklepu z aplikacjami:
    </p>
    <p><span class="bullet dot">•</span>Google Play Store: <a href="https://policies.google.com/privacy?hl=pl">https://policies.google.com/privacy?hl=pl</a></p>
    <a [routerLink]="['/']" class="return-anchor bottom"><i class="fa-solid fa-arrow-turn-down-left"></i>Powrót do strony głównej</a>
  </div>
</div>
